.footer {
  position: relative;
  background-color: $primary;
  color: $negative;
  padding-top: 100px;
  padding-bottom: 40px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;

  &--column-title {
    margin-bottom: 55px;
    font-family: $main-font-bold;
  }

  &--column-text {
    font-size: 16px;
    line-height: 36px;

    & a {
      display: block;
      color: $negative;
      text-decoration: none;
    }
  }

  &--column-text-first {
    margin-bottom: 55px;

    & a {
      color: $secondary;
    }
  }

  &--legal-text {
    color: $primary-80;
    font-size: 14px;
    margin-top: 60px;
  }
}

.row.footer--legal-text {
  margin-bottom: 0;
  line-height: 19px;
}

.social--media {
  display: flex;
  flex-direction: row;

  & .social {
    margin-right: 28px;

    & img {
      height: 32px;
      width: auto;
    }

    & a img {
      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;
    }

    & a img:hover {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &--youtube img {
      position: relative;
      height: 28px;
      bottom: -4px;
    }
  }
}

// Small devices (landscape phones, less than 768px) SM
@media (max-width: 767.98px) {
  .social--media {
    margin-top: 25px;
  }
}
