nav#nav-side {
  scrollbar-width: none !important;
}

nav#nav-side::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
  position: relative;
  font-family: $main-font;
  font-size: 20px;
  line-height: 30px;
  color: $primary;
  overflow: visible;

  &.no-scroll {
    overflow: hidden;
  }
}

.color--primary {
  background: $primary;
}

.color--primary-80 {
  background: $primary-80;
}

.color--primary-60 {
  background: $primary-60;
}

.color--secondary {
  background: $secondary;
}

.color--gradient-primary {
  background: $gradient-primary;
}

.color--background {
  background: $background;
}

.color--negative {
  background: $negative;
}

.row-button {
  margin-top: 20px;
  margin-bottom: 60px;
}

.row-square {
  margin-top: 20px;
}

button:focus {
  outline:0;
}

h1 {
  font-size: 92px;
  line-height: 103px;
  font-family: $main-font-bold;
  color: $primary;
}

h2 {
  font-size: 50px;
  line-height: 60px;
  font-family: $main-font-bold;
  color: $primary;
  margin-bottom: 35px;
}

h3 {
  font-size: 33px;
  line-height: 40px;
  font-family: $main-font-bold;
  color: $primary;
  margin-bottom: 20px;
}

h4 {
    font-size: 20px;
    line-height: 30px;
    font-family: $main-font-bold;
    color: $primary;
    margin-bottom: 20px;
  }

.text--intro {
  font-size: 27px;
  line-height: 39px;
  margin-top: 40px;
}

.text--image-caption {
  font-size: 14px;
  line-height: 20px;
  color: $primary-60;
  margin-top: 24px;
}

#first-content-anchor {
  position: relative;
  top: 0;
  padding-bottom: 96px;
  z-index: -1;
}

.section {
  padding-top: 96px;
  padding-bottom: 96px;

  & h3,
  & h4 {
    margin-bottom: 40px;
  }
}

body.elements-page h2 {
  padding-top: 220px;
  margin-top: -220px;
}

body.elements-page h3,
body.elements-page h4 {
  padding-top: 220px;
  margin-top: -160px;
  pointer-events: none;
}

.row--elements-separator {
  border-top: 1px solid $primary-40;
  margin-top: 96px;
  margin-bottom: 96px;
}

.soon {
  filter: saturate(0) opacity(0.5);
  pointer-events: none;
}

.space {
  height: 20px;
  width: 100%;
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1350px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1199.98px) {
  .section {
    padding-top: 84px;
    padding-bottom: 84px;
  }

  body.elements-page h3,
  body.elements-page h4 {
    margin-top: -166px;
  }

  .row--elements-separator {
    margin-top: 84px;
    margin-bottom: 84px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  body.elements-page h3,
  body.elements-page h4 {
    margin-top: -58px;
    padding-top: 130px;
  }

  .text--intro {
    font-size: 24px;
    line-height: 36px;
  }
  .row--elements-separator {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media (max-width: 767.98px) {
  body {
    font-size: 18px;
    line-height: 27px;
  }

  .section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  h1 {
    font-size: 60px;
    line-height: 72px;
  }

  h2 {
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 17px;
  }

  body.elements-page h3,
  body.elements-page h4 {
    margin-top: -70px;
    padding-top: 130px;
  }
}

// Extra small devices (portrait phones, less than 576px) XS
@media (max-width: 575.98px) {
  body {
    font-size: 17px;
    line-height: 25px;
  }

  h1 {
    font-size: 36px;
    line-height: 42px;
  }

  h2 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  h3{
    margin-bottom: 17px;
  }

  .text--intro {
    font-size: 18px;
    line-height: 27px;
  }

  body.elements-page h3,
  body.elements-page h4 {
    margin-top: -80px;
    padding-top: 130px;
  }
}
