.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background-color: $primary;
  color: $negative;
  width: 100%;
  transition: $transition;

  &--brand,
  &--elements {
    font-size: 21px;
    height: 64px;
    line-height: 64px;
  }

  &--play {
    width: 72px;
    height: 72px;
    background-color: $primary;
    background-image: url('../../../public/images/svg/play.svg'); //TODO preveri sintakso!
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%
  }

  &:hover {
    background-color: $secondary;
    color: $negative;
    text-decoration: none;
  }
}

.circle--arrow {
  width: 48px;
  height: 48px;
  background-image: url('../../../public/images/svg/arrow.svg'); //TODO preveri sintakso!
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $secondary;
  border-radius: 50%;
  transition: $transition;

  &:hover {
    background-color: $primary;
    transition: $transition;
  }
}

@media (hover: none) {
  .circle--arrow {
    &:hover {
      background-color: $secondary;
    }
  }
}

.arrow--back-to-top {
  @extend .circle--arrow;

  transform: translate(-50%, -50%);
  position: fixed;
  bottom: 5px;
  right: 100px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
	color: transparent;
}

.button--caption {
  font-size: 16px;
  color: $primary-60;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 11px;
}

.button--download-as-pdf {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: $primary-60;
  width: 100%;
  font-size: 16px;
  height: 64px;
  line-height: 64px;
  transition: $transition;

  & span {
    transition: $transition;
  }

  &:hover {
    color: $secondary;
    text-decoration: none;

    & span {
      fill: $secondary;

      & #pdf-arrow {
        top: 3px;
      }
    }
  }
}

.button--download-as-pdf span {
  display: inline-block;
  padding-right: 20px;
  fill: $primary-60;
  transform: translate(0, -20px);
}

#pdf-arrow,
#pdf-box {
  position: absolute;
  transform: translate(-50%);
  top: 0;
  transition: $transition;
}

.dropdown-toggle::after {
  content: none;
}

.language-selector {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 30px 0;
  font-size: 14px;
  transition: $transition;
  border: none;
  cursor: pointer;

  & a {
    color: $primary;
    text-decoration: none;

    &:hover {
      color: $secondary;
    }
  }

  & span {
    margin-left: 10px;
    padding-right: 5px;
  }

  & path {
    transition: $transition;
  }

  &:hover {
    color: $secondary;
  }

  &:hover path {
    fill: $secondary;
  }
}

.dropdown-menu {
  font-size: 14px;
  border: none;
  padding: 5px 30px;
  left: -30px !important;

  & a {
    padding-left: 0;
    color: $primary;
    transition: $transition;

    &:hover {
      background-color: transparent;
      color: $secondary;
    }
  }
}

.dropdown-item {
  margin: 5px 0;
}

@media (max-width: 991.98px) {
  .button {
    &--brand {
      font-size: 21px;
      line-height: 72px;
      height: 72px;
      margin-top: 60px;
    }

    &--elements {
      font-size: 18px;
      line-height: 60px;
      height: 60px;
    }

    &--download-as-pdf {
      font-size: 14px;
    }

    &--caption {
      font-size: 14px;
    }
  }
}

@media (max-width: 1649.98px) {
  .arrow--back-to-top {
    right: 50px;
  }
}

@media (max-width: 1499.98px) {
  .arrow--back-to-top {
    right: 25px;
  }
}

@media (max-width: 1349.98px) {
  .arrow--back-to-top {
    right: 0px;
  }
}
