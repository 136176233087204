body.landing .container--header-nav {
    justify-content: flex-end;
    justify-content: space-between;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: $negative;
    // transition: $transition;

    // &.is-active {
    //     background-color: yellowgreen;
    //     transition: $transition;
    // }
}

.header-hide {
    position: relative;
    top: 0;
    transition: top 0.5s ease-out;
    background: $negative;
    z-index: -1;

    &.nav-up {
        top: -144px;
    }
}

.preheader {
    display: flex;
    height: 48px;
    width: 100%;
    background-color: $primary;
    color: $negative;
    font-size: 16px;
    font-family: $main-font-bold;
    line-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;

    & .logout {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-family: $main-font;
        transition: $transition;
        border: none;
        cursor: pointer;
        z-index: 5;

        & a {
            color: $negative;
            text-decoration: none;

            &:hover {
                color: $secondary;
            }
        }
    }

    & span {
        bottom: 1px;
        position: relative;
    }


    & svg {
        transform: rotate(180deg);
    }

    & a {
        color: $negative;
        text-decoration: none;
    }
}

.header {
    height: 96px;

    &--logo {
        display: inline-block;
        position: absolute;
        pointer-events: none;

        & img {
            height: 96px;
        }

        & a {
            color: $primary;
            text-decoration: none;
            pointer-events: all;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--text {
        display: inline-block;
        font-size: 21px;
        line-height: 22px;
        font-family: $main-font-bold;
        margin-left: 21px;
        width: auto;
        transition-delay: 0.4s;
    }
}

header.is-active {
    & a {
        // color: $primary;
        text-decoration: none;
    }

    & .header--text {
        color: $negative;
        transition-delay: 0.275s;
    }

    & .container--header-nav {
        pointer-events: none;
        // background-color: $primary;
        // transition: $transition;
    }
}

.container--header-nav {
    height: 96px;
    display: flex;
    justify-content: center;

    & .empty {
        width: 48px;
    }
}

.header--logo svg {
    height: 96px;
    width: 96px;
}

.dropdown--language-selector {
    width: 48px;
}

nav {
    font-size: 18px;
    line-height: 96px;
}

.menu-item {
    margin-left: 18px;
    margin-right: 18px;


    &.active a {
        color: $secondary;
        pointer-events: none;
    }

    & a {
        color: $primary;
        text-decoration: none;
        transition: $transition;


        &:hover {
            color: $secondary;
        }
    }
}

nav.menu--primary ul.nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 1199.98px) {
    .header {
        height: 80px;

        &--logo img {
            height: 80px;
        }

        &--text {
            font-size: 18px;
            // width: auto;
            height: 48px;
            //   padding-left: 15px;
            margin-left: 18px;
        }

        &--logo a svg {
            height: 80px;
            width: 80px;
        }
    }

    .preheader {
        height: 40px;
        font-size: 16px;
        line-height: 40px;

        & .logout {
            font-size: 16px;
        }
    }

    .container--header-nav {
        height: 80px;
        // background-color: $negative;
        // transition: $transition;
    }

    .header--logo svg {
        height: 80px;
        width: 80px;
    }

    nav {
        line-height: 78px;
    }
}

@media (max-width: 991.98px) {
    .menu-item {
        font-size: 16px;
        margin-left: 12px;
        margin-right: 12px;
    }

    nav.menu--primary {
        display: none;
    }

    .header--text {
        // width: auto;
        line-height: 48px;
    }

    .dropdown--language-selector,
    .logout {
        display: none;
    }

    .container--header-nav {
        justify-content: flex-end;
    }
}

@media (max-width: 767.98px) {
    .header {
        height: 64px;

        &--logo img {
            height: 64px;
        }

        &--text {
            font-size: 16px;
            // width: auto;
            margin-left: 14px;
        }

        &--logo a svg {
            width: 64px;
            height: 64px;
        }
    }

    .preheader {
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        padding-left: 10px;

        & .logout {
            font-size: 12px;
        }

        & svg {
            scale: 0.8;
        }
    }

    .container--header-nav {
        height: 64px;
    }

    .header--logo svg {
        height: 64px;
        width: 64px;
    }

    nav {
        line-height: 64px;
    }

    #nav-mobile .logout {
        margin-right: 15px;
        margin-top: -9px;
        font-size: 12px;
    }
}
