/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'TriglavTheSans';
  src: url('../../../public/fonts/Triglav5.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Triglav5.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../public/fonts/Triglav5.otf') format('opentype'), /* Open Type Font */
    url('../../../public/fonts/Triglav5.svg') format('svg'), /* Legacy iOS */
    url('../../../public/fonts/Triglav5.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../public/fonts/Triglav5.woff') format('woff'), /* Modern Browsers */
    url('../../../public/fonts/Triglav5.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TriglavTheSansBold';
  src: url('../../../public/fonts/Triglav7.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Triglav7.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../../public/fonts/Triglav7.otf') format('opentype'), /* Open Type Font */
    url('../../../public/fonts/Triglav7.svg') format('svg'), /* Legacy iOS */
    url('../../../public/fonts/Triglav7.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../../public/fonts/Triglav7.woff') format('woff'), /* Modern Browsers */
    url('../../../public/fonts/Triglav7.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

// color variables
$body: #222222;
$primary: #222222;
$primary-80: #585858;
$primary-60: #949494;
$primary-40: #bebebe;
$secondary: #ed1c24;
$background: #efefef;
$negative: #fff;


$gradient-primary: transparent linear-gradient(45deg, #891a1c 0%, #a41a1e 10%, #be1b20 23%, #d31b21 36%, #e11b23 52%, #ea1b23 70%, #ed1c24 100%) 0% 0% no-repeat padding-box;

$main-font: 'TriglavTheSans', sans-serif;
$main-font-bold: 'TriglavTheSansBold', sans-serif;

$transition: all 0.3s ease-in-out;

$menu-transition: 0.5s;
$menu-transition-delay: $menu-transition/2;

.menu-closed {
  max-height: 0;
  transition-property: all;
  transition-duration: $menu-transition;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  padding-bottom: 0;
}

.menu-opened {
  max-height: 100vh;
  transition-property: all;
  transition-duration: $menu-transition;
  transition-delay: $menu-transition-delay;
  transition-timing-function: ease-in-out;
  overflow: auto;
  animation: hide-scroll 1s backwards;
  @keyframes hide-scroll {
    from, to { overflow: hidden; }
  }
}

.zavod-vse-bo-v-redu .arrow--back-to-top {
    background-color: #00783f;
}
