*:focus {
  outline: 0;
}

body.brand-page,
body.elements-page {
  & .hamburger {
    margin-top: 10px;
  }

  & nav#nav-mobile ul.level-1 {
    top: 148px;
  }
}

.hamburger {
  float: right;
  display: none;
  top: 0;
  // scale: 0.6;
  position: relative;
  margin-top: -64px;
  border: none;
  background: none;

  &--squeeze.is-active .hamburger-inner::after,
  &--squeeze.is-active .hamburger-inner {
    pointer-events: all;
    background-color: $negative;
  }
}

// .hamburger-box {
//   width: 34px;
// }

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  height: 3px;
  width: 24px;
  border-radius: 0;
  background-color: $primary;
}

.hamburger-inner:after {
  bottom: -7px;
}

.hamburger-inner:before {
  top: -7px;
}

nav#nav-mobile {
    pointer-events: all;

    & .logout {
        pointer-events: all;
        display: block;
        position: relative;
        font-size: 19px;
        top: 0;
        float: right;

        & a {
            color: $negative;
        }
    }

    & li a {
        cursor: pointer;
    }
}

nav#nav-mobile {
  @extend .menu-closed;

  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $primary;
  color: $negative;
  font-size: 27px;
  line-height: 32px;
  z-index: -1;
  padding-left: 80px;
  padding-right: 80px;

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-active {
    @extend .menu-opened;
  }

  & ul {
    list-style-type: none;
    line-height: 32px;

    & a {
      color: $negative;

      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }

    &.level-1 {
      position: relative;
      top: 108px;
      padding-inline-start: 0;

      & > li:before {
        content: ">";
        position: absolute;
        right: 18px;
        transform: rotate(90deg);
        transition: $transition;
        transition-delay: $menu-transition-delay;
      }

      & li {
        line-height: 30px;
        padding-left: 18px;
        // margin-right: 40px;
        border-bottom: 1px solid $primary-60;

        &.is-active:before {
          transform: rotate(270deg);
          transition: $transition;
          transition-delay: $menu-transition-delay;
        }
      }

      & > li {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 0;
      }

      & li.level-1 a {
        pointer-events: none;
      }
    }

    &.level-2 {
    //   margin-left: -15px;
      padding-left: 0;
      @extend .menu-closed;

      &.expand {
        @extend .menu-opened;

      }

      & li {
        border: none;
        font-size: 14px;
        line-height: 32px;

        & a {
          pointer-events: none;
        }

        &.active a {
          color: $secondary;
        }
      }

      & li ul.level-3 li a {
        pointer-events: all;
        color: $primary-80;

        &:hover {
          color: $secondary;
        }
      }

      & ul.level-3 {
        @extend .menu-closed;
        padding-left: 0;

        &.open,
        &.active {
          @extend .menu-opened;
        }

        & li.active a {
          color: $negative;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  body.landing .hamburger {
    pointer-events: all;
    display: block;
    position: absolute;
    margin-top: 0;
    top: 10px;
    right: 0;
  }

  .hamburger {
    display: block;
  }
}

@media (max-width: 767.98px) {
  nav#nav-mobile {
    padding-left: 64px;
    padding-right: 64px;

    & .logout {
        font-size: 16px;
    }
  }

  body.brand-page,
  body.elements-page {
    & .hamburger {
      margin-top: 5px;
    }
  }
}

@media (max-width: 359.98px) {
    .brand-control-header .is-active .header--text {
        display: none;
    };
}
