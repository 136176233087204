.brand-page {
  & .banner {
    // top: 144px;
    // height: calc(100vh - 144px);
  }

  & #first-content-anchor {
    padding-bottom: 144px;
  }
}

.banner {
  position: relative;
  background-size: cover;
  background-position: center top;
  background-color: $primary-40;
  background-blend-mode: multiply;
  color: $negative;
  transition: all 0.3s ease-in-out;
  // height: calc(100vh - 96px);
  // top: 96px;

  &--container {
    position: relative;
    transform: translateY(-50%);
    top: 57%
  }

  &--container-play {
    // top: 75%
  }

  &--title {
    margin-bottom: 22px;

    & h1 {
      color: $negative;
      font-size: 50px;
      line-height: 60px;
    }
  }

  &--text {
    font-size: 20px;
    line-height: 28px;
  }
}

// NOTE Brand banner below //
.banner--title-brand {
  position: absolute;
  bottom: 96px;
}
//  NOTE modal video //
.modal-content {
  border-radius: 0;

  & video {
    max-width: 100%;
  }
}

// Large devices (desktops, less than 1200px) LG
@media (max-width: 1199.98px) {
  .brand-page {
    & .banner {
      // top: 120px;
      // height: calc(100vh - 120px);
    }

    & #first-content-anchor {
      padding-bottom: 120px;
    }
  }

  .banner {
    // height: calc(100vh - 80px);
    // top: 80px;
  }
}

@media (max-width: 991.98px) {
  .banner--title h1 {
    // font-size: 60px;
    // line-height: 69px;
  }

  .banner--text {
    // font-size: 24px;
    // line-height: 36px;
    // padding-top: 96px;
  }

  .banner--title-brand h1 {
    font-size: 48px;
    line-height: 55px;
  }
}

@media (max-width: 767.98px) {
  .brand-page {
    & .banner {
      // top: 88px;
      // height: calc(100vh - 88px);
    }

    & #first-content-anchor {
      padding-bottom: 88px;
    }
  }

  .banner {
    // height: calc(100vh - 64px);
    top: 64px;
  }

  .banner--title {
    margin-top: 0;

  & h1 {
    font-size: 36px;
    line-height: 42px;
    }
  }

  .banner--text {
    font-size: 18px;
    line-height: 27px;
    // bottom: 48px;
  }
}

// Extra small devices (portrait phones, less than 576px) XS
@media (max-width: 575.98px) {
  .banner {
    &--container,
    &--container-play {
      top: 50%
    }
  }
}
