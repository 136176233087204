.header--text-error {
    color: $negative;
}

body.error-page {
    background: $primary;

    & .content--error {
        height: 100vh;

        & .container--error {
            position: relative;
            transform: translateY(-50%);
            top: 50%;

            & h4 {
                display: inline-block;
                color: $secondary;
                font-family: $main-font-bold;
                font-size: 20px;
                margin-bottom: 64px;
            }

            & h1 {
                color: $negative;
            }

            & .button.submit {
                font-family: $main-font-bold;
                background-color: $secondary;
                max-width: 360px;
                height: 64px;
                margin-top: 64px;
                font-size: 20px;

                &:hover {
                    background-color: $primary-80;
                }
            }
        }
    }
}
