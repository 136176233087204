.login.header--text {
    color: $negative;
    width: 100%;
    display: inline;
}

.login-page {
    background-image: url('/images/_landing/login-hero-image.jpg');
    background-size: cover;
    background-color: $primary-40;
    background-blend-mode: multiply;
    color: $negative;
    position: relative;
    height: 100vh;
    padding-top: 96px;

    & .container--login {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -65px;
    }

    &--form {
        max-width: 390px;
        position: relative;

        // top: 25%;

        & h1 {
            font-size: 50px;
            line-height: 50px;
            font-weight: 900;
            color: $negative;
            margin-bottom: 36px;
        }

        & label {
            width: 100%;
            font-size: 15px;
        }


        & input {
            width: 100%;
            height: 48px;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 0;
            border: none;
            padding-left: 15px;
            padding-right: 15px;
            color: $primary;
            font-size: 17px;
            font-weight: 700;
            transition: $transition;

            &#email,
            &#password {
                &:hover {
                    background-color: rgba(255, 255, 255, 0.9);
                }

                &:focus {
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }

        & input[type=checkbox] {
            display: inline;
            width: 24px;
            height: 24px;
            background: transparent;
        }

        & .forgetmenot {
            & label {
                display: inline;
                position: relative;
                font-size: 15px;
                // margin-left: 18px;
                margin-bottom: 0;
                bottom: 5px;
                padding-top: 2px;
            }

            & input[type=checkbox] {
                background-color: transparent;
            }
        }

        & .button.submit {
            font-family: $main-font-bold;
            background-color: $secondary;

            &:hover {
                background-color: $primary;
            }

            & input {
                font-weight: 400;
            }
        }
    }

    & a {
        font-size: 15px;
        // font-weight: 700;
        color: $negative;
        text-decoration: none;
    }
}

// JF custom checkbox //
.rememberme {
    display: block;
    position: relative;
    padding-left: 42px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.rememberme input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0;
    border: none;
    transition: $transition;
}

/* On mouse-over, add a grey background color */
.rememberme:hover input~.checkmark {
    background-color: rgba(255, 255, 255, 1);
}

/* When the checkbox is checked, add a  background */
.rememberme input:checked~.checkmark {
    background-color: rgba(255, 255, 255, 0.9);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.rememberme input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.rememberme .checkmark:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 13px;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// JF-END cutom chekbox //

.column-remember {
    padding-top: 24px;
    padding-bottom: 24px;
    line-height: 0;
}

a.pass-reset-button::after {
    content: url('/images/svg/link.svg');
    position: absolute;
    padding: 4px;
}

a.pass-reset-button {
    margin-top: 1rem;
}

.button.submit {
    height: 48px;
    font-size: 17px;
    border: none;
}

.footer--login-text {
    position: relative;
    height: 0;
    top: -55px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}

.alert.alert-success {
    background: none;
    border: none;
    margin: 15px -15px;
    padding: 0;
    color: $negative;
}

.registration-alert-success {
    background: none;
    color: $primary-40;

    & span {
        font-family: $main-font-bold;
        color: $negative;
    }
}


.button--reset {
    margin-top: 36px;
}

@media (max-width: 1199.98px) {
    .login-page {
        & .container--login {
            margin-top: -68px;
        }
    }
}

@media (max-width: 767.98px) {
    .login-page {
        & .container--login {
            margin-top: -50px;
        }
    }
}

// Extra small devices (portrait phones, less than 576px) XS
@media (max-width: 575.98px) {
    .login-page {
        &--form {
            max-width: inherit;
        }
    }

    .login.header--text {
        display: none;
    }
}
