.cpanel--background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-image: url('/images/_landing/login-hero-image.jpg');
    background-size: cover;
    background-position: top center;
    background-color: $primary-40;
    background-blend-mode: multiply;
    z-index: -1;
}

body.control-panel {
    & .logout {
        position: absolute;
        right: 96px;
        top: 32px;
        font-size: 18px;

        & a {
            color: $primary;

            &:hover {
                text-decoration: none;
                color: $secondary;
            }
        }
    }

    & .title-row {
        margin-bottom: 48px;
    }

    & h2 {
        color: $negative;
    }

    & .header--text {
        color: $primary;
    }

    & .header--logo {
        width: 100%;
        background: $negative;
    }

    & .alert.alert-success {
        font-size: 1.5em;
        line-height: 1.5em;
        color: $primary-40;

        & span {
            font-family: $main-font-bold;
            color: $negative;
            font-size: 1em;
        }
    }

    & .row--cpanel.row--first {
        position: sticky;
        top: 96px;
        background: rgba(255, 255, 255, 0.9);
        z-index: 1;
    }

    & .add-user-icon {
        // width: 40px;
        pointer-events: all;
        // margin-right: 36px;
    }

    & .button {
        border-radius: 0;
        width: auto;
        padding-left: 35px;
        padding-right: 35px;
        margin-left: 15px;

        &--cancel:hover {
            background-color: $primary-80;
        }
    }

    & form {
        margin: 0;
        padding: 0;
    }

    & .modal-content {
        background-color: $primary-40;
        color: $primary;
        line-height: 28px;
        border: none;

        & .modal-header {
            font-family: $main-font-bold;
            background-color: $secondary;
            color: $negative;
            border: none;
            border-radius: 0;

            & button span {
                color: $negative;
                font-size: 24px;
            }
        }

        & .modal-body {
            text-align: left;

            & .modal-row {
                margin-left: -15px;
                margin-right:  -15px;
            }

            & span {
                font-family: $main-font-bold;
                font-size: 24px;
                line-height: 36px;
            }

            & label {
                width: 100%;
                font-size: 15px;
                margin-bottom: 0;
                margin-top: 15px;
            }

            & input:not([type='checkbox']) {
                width: 100%;
                height: 48px;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 0;
                border: none;
                padding-left: 15px;
                padding-right: 15px;
                color: $primary;
                font-size: 17px;
                font-weight: 700;
                transition: $transition;

                &#email,
                &#password {
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.9);
                    }

                    &:focus {
                        background-color: rgba(255, 255, 255, 1);
                    }
                }
            }

            &.delete-warning {
                color: $secondary;
                font-family: $main-font-bold;
            }
        }
    }
}

.section--cpanel {
    margin-top: 115px;
    line-height: 18px;

    & span {
        font-size: 12px;
    }

    & .row {
        height: 50px;
        color: $primary;

        &.row--cpanel {
            font-family: $main-font-bold;
            color: $primary;
            pointer-events: none;
        }
    }

    & .row.is-admin {
        color: $secondary;

        & img {
            filter: invert(14%) sepia(97%) saturate(4556%) hue-rotate(351deg) brightness(98%) contrast(90%);
        }
    }

    & .row:nth-child(2n) {
        background: rgba(255,255,255, 0.6);
        transition: all 0.15s ease-in-out;

        &:hover {
            // color: $primary;
            background: rgba(255,255,255, 0.8);
            transition: all 0.15s ease-in-out;
        }
    }
    & .row:nth-child(2n+3) {
        background: rgba(255,255,255, 0.5);
        transition: all 0.15s ease-in-out;

        &:hover {
            // color: $primary;
            background: rgba(255,255,255, 0.8);
            transition: all 0.15s ease-in-out;
        }
    }

    & .col-delete {
        padding-right: 15px;

        & .delete-button.disabled {
            pointer-events: none;

            & button {
                background-color: $primary-60;
            }
        }
    }
}

#edit-user-icon {
    margin-right: 15px;
    margin-left: 15px;
}

// Administrator switch //
.switch-field {
	display: flex;
	margin-bottom: 36px;
	overflow: hidden;
    width: 120px;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: $primary-40;
	color: $negative;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: $primary;
	box-shadow: none;

    &[id^="is-admin-no"] {
        background-color: #891a1c;
    }

    &[id^="is-admin-yes"] {
        background-color: #0d6c28;
    }
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

@media (max-width: 1199.98px) {
    body.control-panel .row--cpanel.row--cpanel.row--first {
        top: 80px;
    }
}

@media (max-width: 991.98px) {
    body.control-panel .button {
        padding-left: 10px;
        padding-right: 10px;
    }

    .add-user-icon {
        margin-right: 12px;
    }

    .row--cpanel.row--first {
        top: 80px;
    }
}

@media (max-width: 767.98px) {
    .section--cpanel {
        font-size: 16px;
    }
}
