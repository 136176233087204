.section--brand:nth-child(2n-1) {
  background-color: $background;
}

// .section--brand h2 {
//   margin-bottom: 36px;
// }

.square {
  margin-top: 60px;
  margin-bottom: 30px;

  &--text-barnad {
    font-size: 24px;
    font-family: $main-font-bold;
    margin-top: 24px;
    margin-bottom: 0px;
  }
}

@media (max-width: 991.98px) {
  .square {
    margin-top: 36px;
    margin-bottom: 18px;

    &--text-barnad {
      font-size: 21px;
      line-height: 29px;
      margin-top: 12px;
      // margin-bottom: 18px;
    }
  }
}

@media (max-width: 767.98px) {
  .square--text-barnad {
    font-size: 18px;
    line-height: 21px;
    margin-top: 6px;
    // margin-bottom: 0;
  }

  .square {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
