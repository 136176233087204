.section--landing:nth-child(2n + 1) {
  background-color: $background;
}

body.landing .square {
  margin-top: 15px;
  margin-bottom: 15px;
}

.square {
  margin-top: 15px;
  margin-bottom: 15px;

  &--image {
  background-size: 100%;
  background-position: center;
  background-blend-mode: multiply;
  transition: $transition;

    &-overlay {
      background-color: $primary-40;
    }
  }

  & span {
    position: absolute;
    color: $negative;
    font-family: $main-font-bold;
    font-size: 24px;
    line-height: 29px;
    bottom: 0;
    padding: 25px 30px 25px 25px;
  }
}

.square--image:before{
	content: "";
	display: block;
	padding-top: 100%; 	/* initial ratio of 1:1*/
}

.square--image:hover {
  background-size: 110%;
}

.container--header-nav .logout {
  display: flex;
  font-size: 18px;
  line-height: 96px;

  & a {
    color: $primary;
    text-decoration: none;

    &:hover {
      color: $secondary;
    }
  }
}

@media (max-width: 1199.98px) {
  .container--header-nav .logout {
    line-height: 80px;
  }
}

@media (max-width: 991.98px) {
  .square span {
    font-size: 21px;
    line-height: 24px;
    padding: 15px 20px 15px 15px;
  }

  .container--header-nav .logout {
    line-height: 80px;
    display: none;
  }
}
