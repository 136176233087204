.container--side-menu {
  margin-left: 0;
  margin-right: -48px;
}

nav#nav-side {
  position: fixed;
  top: 230px;
  margin-left: 36px;
  font-size: 16px;
  line-height: 48px;
  height: calc(100vh - 230px);
  max-width: 29%;
  overflow-y: auto;
  align-content: flex-start;
  color: $primary;

  & .container-fluid {
    overflow: hidden;
    }

  &.is-active {
    @extend .menu-opened;

    color: $secondary;
  }

  & ul {
    list-style-type: none;
    line-height: 32px;

    & a {
      color: $primary;

      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }

    &.level-1 {
      position: relative;
      cursor: pointer;

      & li.level-1 a {
        display: none;
      }
    }

    &.level-2 {
      margin-left: -15px;
      padding-left: 0;
      @extend .menu-closed;

      &.expand {
        @extend .menu-opened;

        padding-bottom: 15px;
        overflow: hidden;
      }

      & li.is-active a {
        color: $secondary;
      }

      & li {
        border: none;
        // font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        margin-top: 10px;

        &.level-2 a {
          display: block;
        }

        & a {
          pointer-events: none;
        }

        &.level-2-iz a {
          pointer-events: auto;
        }

        &.active a {
          color: $secondary;
        }
      }

      & li ul.level-3 li a {
        pointer-events: all;
        color: $primary-60;

        &:hover {
          color: $primary;
        }
      }

      & ul.level-3 {
        @extend .menu-closed;
        padding-left: 25px;
        overflow: hidden;

        &.open,
        &.active {
          @extend .menu-opened;

        }

        & li.active a {
          color: $secondary;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  nav#nav-side {
    margin-left: 0;
    padding-left: 0;
    display: none;
  }
}
