.elements-page {
    background-color: $background;

    & .header--logo img {
        margin: 0;
    }

    & img,
    & video {
        max-width: 100%;
        margin-top: 24px;
        margin-bottom: 48px;
    }

    &.text--image-caption-elements {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.row--elements,
.row--elements-first {
    margin-top: 0;

    &-first {
        margin-top: 144px;
    }

    & ul li {
        margin-left: 20px;
        // margin-top: 150px;
        margin-bottom: 20px;
    }
}

.row--elements h3 {
    margin-top: 96px;
}

.row--elements h4 {
    margin-top: 48px;
}

.elements-text {
    // margin-top: 20px;
    margin-bottom: 20px;
}

.space-image-text {
    height: 20px;
}

.elements-colors {
    padding: 25px;
    color: $negative;
    font-size: 16px;
    line-height: 22px;

    &--title {
        font-family: $main-font-bold;
        margin-bottom: 18px;
    }

    &--values {
        font-family: $main-font;
    }
}

@media (max-width: 1199.98px) {
    .text--image-caption-elements {
        margin-top: 0;
        margin-bottom: 24px;
    }

    // .row--elements-first h2 {
    //   margin-bottom: 84px;
    // }

    .elements-page img {
        margin-bottom: 12px;
    }
}

@media (max-width: 991.98px) {
    // .row--elements-first h2 {
    //   margin-bottom: 48px;
    // }
}

@media (max-width: 767.98px) {
    .row--elements-first {
        margin-top: 96px;
    }
}
